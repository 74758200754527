<template>
	<div>
		<!-- 图片 -->
		<div class="swiper-box-index">
			<!-- 轮播 -->
			<div class="swiper-index">
				<swiper :slidesPerView="1" :spaceBetween="30" :loop="true" :autoplay="true" :pagination="pagination" :navigation="navigation" class="mySwiper">
					<swiper-slide v-for="(item,index) in items" :key='index' @click="godetails(item)">
	
						<el-image
						     class="swiper-pic display-block w100 h100"
						      :src="item.pc_image"
						      fit="fit"></el-image>
					</swiper-slide>
					<div class="swiper-button-prev"></div>
					<div class="swiper-button-next"></div>
				</swiper>
			</div>
		</div>
		
		<div class="center flex-column">
			<div class="w1200">
				<div class="h100px align-items-center fz40 co-333333">诚信机构</div>
			</div>
			
			<!-- 列表 -->
			<div class="w100 bg-F7F7F7 center flex-column">
				<div class="w1200">
					<div class="justify-content-between paddt20 paddb20">
						<div class=" display-flex">
							<div class="fz20 co-333333 display-flex" style="width: 70px;" >地区</div>
							<div class="display-flex flex-wrap">
								<div class="title-item center cure-pointer fz14" v-for="(item, i) in pagesinfo.area" :key="i"
								:class="[titleIndex == i ? 'bg-DCFF03 co-010085' : 'co-333333', i == 0 ? 'marl20' : '']" @click="titleChoose(1,i)">{{item.name}}</div>
							</div>
						
						</div>
						
						<div class="center display-flex sort cure-pointer relative"  @click="pullClick">
							<div class="fz14 co-010085 " style="width: 70px;">综合排序</div>
							<img class="pull-pic w100 marl4 display-block" :class="pull ? 'rotate' : ''" src="@/assets/img/pull.png" alt="">
							<img class="triangle display-block absolute z100" v-if="pull" src="@/assets/img/triangle.png" alt="" />
							<div class="absolute pull-box z100" :style="`height: ${pull ? '130px' : '0px'};`">
								<div class="justify-content-center-between paddlr12 h40" v-for="(item, i) in selectList" :key="i" @click.stop="selectChoose(i)">
									<span class="fz14" :class="seletIndex == i ? 'co-010085' : 'co-333333'">{{item}}</span>
									<img class="check_icon display-block" v-if="seletIndex == i" src="@/assets/img/check_icon.png" alt="" />
								</div>
							</div>
						</div>
					</div>
					
					<div class="justify-content-center-between paddb40">
						<div class="align-items-center">
							<span class="fz20 co-333333"  style="width: 70px;">专业</span>
							<div class="title-item center cure-pointer fz14" v-for="(item, i) in pagesinfo.type" :key="i" 
							:class="[titleListTwoIndex == i ? 'bg-DCFF03 co-010085' : 'co-333333', i == 0 ? '' : '']" @click="titleChoose(2,i)">{{item.name}}</div>
						</div>
					</div>
					
					<div style="min-height: 1120px;">
						<SincerityList :list="list"></SincerityList>
						<loadMore :state="state" loading_icon></loadMore>
					</div>
					
					<div class="center paddt54 paddb15">
						<el-pagination background  class="center "  @current-change="handleCurrentChange"
						    layout="prev, pager, next, jumper" :total="classroomlist.length" :page-size="size">
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
		<div v-loading.fullscreen.lock="fullscreenLoading"></div>
	</div>
</template>

<script>
	// Import Swiper Vue.js components
	import { Swiper, SwiperSlide } from 'swiper/vue';
	
	// Import Swiper styles
	import 'swiper/swiper.less';
	
	import "swiper/components/pagination/pagination.min.css"
	import "swiper/components/navigation/navigation.min.css"
	
	import SwiperCore, { Pagination,Navigation, Autoplay } from 'swiper/core';
	
	SwiperCore.use([Pagination, Navigation, Autoplay]);
	
	export default {
		name: 'Sincerity',
		components: {
			Swiper,
			SwiperSlide,
		},
		data() {
			return {
				fullscreenLoading: true,
				items:[],
				titleList: ['全部', '中国', '加拿大'], // 筛选地区
				titleListTwo: ['全部', '建筑设计', '景观设计', '商业区'], // 筛选标题
				titleIndex: 0, // 筛选地区选中
				titleListTwoIndex: 0, // 筛选标题选中
				pull: false, // 是否下拉
				selectList: ['综合排序', '最新', '最热'], // 下拉列表
				seletIndex: 0, // 下拉选中
				pagination: {
					"clickable": true,
					"bulletActiveClass": "box-01-active",
					"type": 'bullets'
				},
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				state: 1, // 列表加载状态
				page: 1, // 分页
				loading: false, // 接口加载状态		
				list: [], // 列表
				pagesinfo:{ //页面内容
					
				},
				dictTotal: 0,
				size: 16,
				dataObj: {
					type_id: 0, //专业
					city_id:0,
					sort:0,
				},
				classroomlist: [],
			}
		},
		mounted() {
			this.$req({
				method: 'get',
				data: {
					type: 'agency'
				},
				url: '/api/maple/carousel',
				success: res => {
					this.items = res
				},
				fail: error => {

				}
			});
			this.$req({
				method: 'get',
				url: '/api/agency/search',
				success: res => {
					this.pagesinfo = res;
					this.pagesinfo.area.unshift({
						name: '全部',
						id: 0
					})
					this.pagesinfo.type.unshift({
						name: '全部',
						id: 0
					})
				},
				fail: error => {
			
				}
			});
			this.getlist();
		},
		methods: {
			getlist() {
				if (this.loading || this.state > 1) return;
				// let page = parseInt(this.page) + 1;
				this.loading = true;
				this.state = 1;
				let size = 50;
				this.$req({
					method: 'get',
					data: {
						type: 'school',
						...this.dataObj,
						page: this.page,
						size: size,
					},
					url: '/api/agency',
					success: res => {
						this.fullscreenLoading = false;
						let list = res;
						this.classroomlist = list;					
						this.paging();
						if (list.length > 0) {
						    this.state = 0;
						} else {
						    this.state = 3;
						}
						this.loading = false;
					},
					fail: error => {
						if (this.list.length == 0) {
							this.state = 3;
						} else {
							this.state = 2;
						}
						this.loading = false;
						this.fullscreenLoading = false;
					}
				});
			},
			// 假分页
			paging() {
			    let all = this.classroomlist;
			    let size = this.size;
			    let index = (this.page - 1) * size;
			    let arr = [];
			    for (let i = 0; i < size; i++) {
			        if (index < all.length) {
			            arr.push(all[index])
			        }
			        index++;
			    }
			    this.list = arr;
			},
			// 筛选标题选中
			titleChoose(type, i) {
				if (type == 1) {
					this.titleIndex = i;
					this.dataObj.city_id= this.pagesinfo.area[i].id
				} else if (type == 2) {
					this.titleListTwoIndex = i
					this.dataObj.type_id = this.pagesinfo.type[i].id
				}
				this.list=[];
				// this.page = 0;
				this.state = 0;	
				this.getlist()
			},
			// 点击下拉
			pullClick() {
				this.pull = !this.pull
			},
			// 下拉选中
			selectChoose(e) {
				this.seletIndex = e
				this.dataObj.sort = e+1;
				// this.page = 0;
				this.state = 0;
				this.list=[];
				this.getlist();
				this.pullClick();
			},
			godetails(item) {
				console.log(item)
				// 1素材市场 2研图社 3竞赛信息 4院校指南 5项目灵感
				let data = item.link_type;
				let id = item.link_id
				let type = '';
				let url = '/Detail';
				if (data == 'source') {
					type = 1
				} else if (data == 'picture') {
					type = 2
				} else if (data == 'competition') {
					type = 3
				} else if (data == 'school') {
					type = 4
				} else if (data == 'inspiration') {
					type = 5
				}
				else if (data == 'teachar') {
					this.$router.push({
						path: '/TeacherDetail',
						query: {
							type: type,
							id: id
						}
					})
					return
				}
				else if (data == 'agency') {
					this.$router.push({
						path: '/SincerityDetail',
						query: {
							type: type,
							id: id
						}
					})
					return
				}
				else if (data == 'course') {
					this.$router.push({
						path: '/ClassDetail',
						query: {
							type: type,
							id: id
						}
					})
					return
				}
				
				this.$router.push({
					path: url,
					query: {
						type: type,
						id: id
					}
				})
			},
			handleCurrentChange(val) {
			    this.getList();
			    let w = document.documentElement.clientWidth || document.body.clientWidth;
			    document.documentElement.scrollTop = document.body.scrollTop = w * 0.4;
			}
		}
	}
</script>

<style lang="less">
	// 轮播
	.swiper-box-index {
		height: 500px;
		
		.swiper-index {
			height: 500px;
		}
		
		.swiper-slide {
			text-align: center;
			font-size: 18px;
			background: #fff;
		
			/* Center slide text vertically */
			display: -webkit-box;
			display: -ms-flexbox;
			display: -webkit-flex;
			display: flex;
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			-webkit-justify-content: center;
			justify-content: center;
			-webkit-box-align: center;
			-ms-flex-align: center;
			-webkit-align-items: center;
			align-items: center;
		}
		
		.swiper-slide img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		
		.swiper-button-next, .swiper-button-prev {
			position: absolute;
			top: 50%;
			width: calc(var(--swiper-navigation-size)/ 44 * 27);
			height: var(--swiper-navigation-size);
			margin-top: calc(0px - (var(--swiper-navigation-size)/ 2));
			z-index: 10;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			color: var(--swiper-navigation-color,var(--swiper-theme-color));
		}
		
		.swiper-button-prev {
			width: 48px;
			height: 48px;
			background: url(../assets/img/prev.png) no-repeat;
			background-position: 0 0;
			background-size: 100%;
			left: 10px;
			right: auto;
		}
		
		.swiper-button-next {
			width: 48px;
			height: 48px;
			background: url(../assets/img/next.png) no-repeat;
			background-position: 0 0;
			background-size: 100%;
			right: 10px;
			left: auto;
		}
		
		.swiper-container {
			width: 100%;
			height: 100%;
		}
		
		.swiper-container {
			margin-left: auto;
			margin-right: auto;
		}
		
		.img-box {
			background: rgba(0, 0, 0, 0.5);
			transition: all .3s;
			display: none;
		}
		
		.swiper-button-prev::after {
			content: '';
		}
		
		.swiper-button-next::after {
			content: '';
		}
		
		.box-01-active {
			background: #FFFFFF !important;
			opacity: 1 !important;
		}
		
		.swiper-pagination {
			position: absolute;
			text-align: center;
			transition: .3s opacity;
			transform: translate3d(0,0,0);
			z-index: 10;
		}
		
		.swiper-pagination-bullet {
			width: 8px;
			height: 8px;
			display: inline-block;
			border-radius: 50%;
			background: #000;
			opacity: .2;
			margin: 0 4px;
		}
		
		.swiper-pagination-bullet:hover {
			cursor: pointer;
		}
		
		.swiper-pagination-bullets {
			bottom: 10px;
			left: 0;
			width: 100%;
		}
	}
	
	.in_02 {
		height: 200px;
	}
	
	/* 筛选标题 */
	.title-item {
		min-width: 70px;
		display: flex;
		padding: 0 5px;
		
		height: 28px;
		border-radius: 3px;
	}
	
	/* 排序 */
	.sort {
		width: 90rpx;
		height: 28px;
		border: 1px solid #010085;
		border-radius: 3px;
		padding: 0 8px;
		
		.pull-pic {
			width: 14px;
			height: 14px;
			transition: all 0.3s;
		}
		
		.rotate {
			transform: rotate(180deg);
		}
		
		.triangle {
			width: 27px;
			height: 15px;
			top: 28px;
			right: 0;
			z-index: 100;
		}
		
		.pull-box {
			width: 140px;
			background: #FFFFFF;
			box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
			top: 43px;
			border-radius: 8px;
			left: 0;
			overflow: hidden;
			transition: all 0.3s;
			
			.check_icon {
				width: 15px;
				height: 15px;
			}
		}
	}
	
	// 改变组件样式
	.el-pager li {
		font-size: 14px !important;
		width: 40px !important;
		height: 40px !important;
		border-radius: 4px !important;
		border: 1px solid #CCCCCC !important;
		line-height: 40px !important;
		padding: 0 !important;
		color: #999999!important;
	}
	
	.btn-prev, .btn-next {
		width: 40px !important;
		height: 40px !important;
		line-height: 40px !important;
		border-radius: 4px !important;
		border: 1px solid #CCCCCC !important;
	}
	
	.el-pager .active {
		background: #DCFF03 !important;
		color: #010085 !important;
		border: 1px solid #DCFF03 !important;
	}
</style>
